<template>
  <div class="education-detail__content" ref="educationDetailTest">
    <div class="education-detail__content-title">
      <span v-html="test.title"></span>
      <div class="education-detail__content-questions-count">
        <span v-if="!showTestResults"
          >Пройдено вопросов {{ activeQuestion }}/{{
            test.questions.length
          }}</span
        >
        <span v-else>Результаты тестирования</span>
      </div>
    </div>
    <div class="education-detail__content-body">
      <div
        class="education-detail__test-question"
        v-for="(question, q_ind) in test.questions"
        v-show="activeQuestion === q_ind"
        :key="q_ind"
      >
        <div
          class="education-detail__question-title"
          v-html="question.title"
        ></div>
        <div class="education-detail__question-answers">
          <div
            class="education-detail__question-checkboxes"
            v-if="question.choices.filter((el) => el.is_correct).length > 1"
          >
            <div
              class="education-detail__question-checkbox"
              v-for="(answer, a_ind) in question.choices"
              :key="a_ind"
            >
              <input
                type="checkbox"
                :id="`checkbox_${q_ind}_${a_ind}`"
                v-model="questionList[q_ind]"
                :value="answer.title"
              />
              <div class="education-detail__question-checkbox-container">
                <div class="education-detail__question-checkbox-correct">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0039 6L6.50391 11L4.00391 8.72727"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <label
                :for="`checkbox_${q_ind}_${a_ind}`"
                class="education-detail__question-label"
                v-html="answer.title"
              ></label>
            </div>
          </div>
          <div class="education-detail__question-radios" v-else>
            <div
              class="education-detail__question-radio"
              v-for="(answer, a_ind) in question.choices"
              :key="a_ind"
            >
              <input
                type="radio"
                :id="`radio_${q_ind}_${a_ind}`"
                v-model="questionList[q_ind]"
                :value="answer.title"
              />
              <div class="education-detail__question-radio-container">
                <div class="education-detail__question-radio-correct">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.00390625"
                      width="10"
                      height="10"
                      rx="5"
                      fill="#6BC399"
                    />
                  </svg>
                </div>
              </div>
              <label
                :for="`radio_${q_ind}_${a_ind}`"
                class="education-detail__question-label"
                v-html="answer.title"
              ></label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="education-detail__test-result"
        v-if="activeQuestion === test.questions.length"
      >
        <div v-if="!showTestResults">
          <div class="education-detail__test-result-title">
            <span style="color: #6bc399" v-if="testPercentage >= 80"
              >Поздравляем!
            </span>
            Вы
            <span style="color: #6bc399" v-if="testPercentage >= 80"
              >прошли</span
            ><span v-else style="color: #ec99c5">не прошли</span> тест по теме
            <br />“{{ activeModule.title }}”
          </div>
          <div
            class="education-detail__test-result-button button"
            v-if="test.file"
            @click="downloadFile(test.file)"
          >
            <svg
              class="mr-2"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.5 2.75C12.9142 2.75 13.25 3.08579 13.25 3.5V13.6893L16.9697 9.96967C17.2626 9.67678 17.7374 9.67678 18.0303 9.96967C18.3232 10.2626 18.3232 10.7374 18.0303 11.0303L13.0303 16.0303C12.9584 16.1022 12.8755 16.1565 12.7871 16.1931C12.6987 16.2298 12.6017 16.25 12.5 16.25C12.3983 16.25 12.3013 16.2298 12.2129 16.1931C12.1245 16.1565 12.0416 16.1022 11.9697 16.0303L6.96967 11.0303C6.67678 10.7374 6.67678 10.2626 6.96967 9.96967C7.26256 9.67678 7.73744 9.67678 8.03033 9.96967L11.75 13.6893V3.5C11.75 3.08579 12.0858 2.75 12.5 2.75ZM3.5 14.75C3.91421 14.75 4.25 15.0858 4.25 15.5V19.5C4.25 19.8315 4.3817 20.1495 4.61612 20.3839C4.85054 20.6183 5.16848 20.75 5.5 20.75H19.5C19.8315 20.75 20.1495 20.6183 20.3839 20.3839C20.6183 20.1495 20.75 19.8315 20.75 19.5V15.5C20.75 15.0858 21.0858 14.75 21.5 14.75C21.9142 14.75 22.25 15.0858 22.25 15.5V19.5C22.25 20.2293 21.9603 20.9288 21.4445 21.4445C20.9288 21.9603 20.2293 22.25 19.5 22.25H5.5C4.77065 22.25 4.07118 21.9603 3.55546 21.4445C3.03973 20.9288 2.75 20.2293 2.75 19.5V15.5C2.75 15.0858 3.08579 14.75 3.5 14.75Z"
                fill="currentColor"
              />
            </svg>
            Скачать памятку
          </div>
        </div>
        <div v-else>
          <div
            class="education-detail__test-question"
            v-for="(question, q_ind) in test.questions"
            :key="q_ind"
          >
            <div
              class="education-detail__question-title"
              v-html="question.title"
            ></div>
            <div class="education-detail__question-answers">
              <div
                class="education-detail__question-checkboxes"
                v-if="question.choices.filter((el) => el.is_correct).length > 1"
              >
                <div
                  class="education-detail__question-checkbox"
                  :class="{
                    'education-detail__question-checkbox_correct':
                      answer.is_correct,
                    'education-detail__question-checkbox_incorrect':
                      !answer.is_correct &&
                      questionList[q_ind].includes(answer.title),
                  }"
                  v-for="(answer, a_ind) in question.choices"
                  :key="a_ind"
                >
                  <div class="education-detail__question-checkbox-container">
                    <div class="education-detail__question-checkbox-correct">
                      <svg
                        v-if="answer.is_correct"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.0039 6L6.50391 11L4.00391 8.72727"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <svg
                        v-else
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 1L1 9M1 1L9 9"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <label
                    :for="`checkbox_${q_ind}_${a_ind}`"
                    class="education-detail__question-label"
                    v-html="answer.title"
                  ></label>
                </div>
              </div>
              <div class="education-detail__question-radios" v-else>
                <div
                  class="education-detail__question-radio"
                  :class="{
                    'education-detail__question-radio_correct':
                      answer.is_correct,
                    'education-detail__question-radio_incorrect':
                      !answer.is_correct &&
                      questionList[q_ind] === answer.title,
                  }"
                  v-for="(answer, a_ind) in question.choices"
                  :key="a_ind"
                >
                  <div class="education-detail__question-radio-container">
                    <div class="education-detail__question-radio-correct">
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.00390625"
                          width="10"
                          height="10"
                          rx="5"
                          :fill="answer.is_correct ? '#6BC399' : '#DA338C'"
                        />
                      </svg>
                    </div>
                  </div>
                  <label
                    :for="`radio_${q_ind}_${a_ind}`"
                    class="education-detail__question-label"
                    v-html="answer.title"
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="education-detail__buttons mt-8"
        v-if="activeQuestion === test.questions.length"
      >
        <div
          class="button button_empty-white d-inline-flex ml-auto"
          @click="showResults()"
        >
          <span v-if="!showTestResults">Посмотреть результаты теста</span>
          <span v-else>Назад</span>
        </div>
        <div
          class="button button_empty-white d-inline-flex ml-4"
          @click="refreshTest()"
          v-if="testPercentage < 80"
        >
          Пройти еще раз
        </div>
        <div
          class="button button_white d-inline-flex ml-4"
          v-if="nextBlock"
          @click="$emit('nextBlockClick')"
        >
          Следующий блок
          <svg
            class="ml-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 4.5L16.5 12L9 19.5"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
      <div class="education-detail__buttons mt-8" v-else>
        <div
          class="button button_white d-inline-flex ml-auto"
          @click="nextQuestion()"
          :class="{
            button_disabled:
              !questionList[activeQuestion] ||
              !questionList[activeQuestion].length,
          }"
        >
          Следующий вопрос
          <svg
            class="ml-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 4.5L16.5 12L9 19.5"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "EducationTest",
  props: {
    test: Object,
    activeModule: Object,
    activeBlock: Object,
    nextBlock: [Boolean, Object],
  },
  data: () => ({
    showTestResults: false,
    questionList: [],
    activeQuestion: 0,
    testPercentage: 0,
  }),
  computed: {
    ...mapGetters(["EducationDetail"]),
  },
  methods: {
    ...mapActions(["fetchEducationModules", "fetchEducationDetail"]),
    nextQuestion() {
      if (this.activeQuestion === this.test.questions.length - 1) {
        this.getTestPercentage();
        if (this.testPercentage >= 80) {
          this.$axios({
            method: "POST",
            url: "/api/education/test_result/",
            data: { test: this.test.id, is_completed: true },
          }).then(() => {
            this.$axios({
              method: "POST",
              url: `/api/education/modules/${this.activeModule.slug}/blocks/${this.activeBlock.slug}/completed/`,
            }).then(() => {
              this.fetchEducationDetail({
                slug: this.$route.params.slug,
                update_only_tests: true,
              });
              this.fetchEducationModules({ slug: this.$route.params.slug });
            });
          });
        } else {
          if (!this.activeBlock.is_completed) {
            this.$axios({
              method: "POST",
              url: `/api/education/modules/${this.activeModule.slug}/blocks/${this.activeBlock.slug}/completed/`,
            }).then(() => {
              this.fetchEducationModules({ slug: this.$route.params.slug });
            });
          }
        }
      }
      if (typeof ym !== "undefined") {
        // console.log(this.test.title.replace(/<[^>]*>?/gm, ""));
        // console.log(this.activeQuestion + 1);
        // console.log(this.activeModule.title.replace(/<[^>]*>?/gm, ""));
        // console.log(this.EducationDetail.title.replace(/<[^>]*>?/gm, ""));
        // console.log(this.questionList[this.activeQuestion].replace(/<[^>]*>?/gm, ""));
      }
      this.activeQuestion++;
    },
    fillQuestionsList() {
      for (let question of this.test.questions) {
        if (question.choices.filter((el) => el.is_correct).length > 1) {
          this.questionList.push([]);
        } else {
          this.questionList.push(null);
        }
      }
      // question.choices.filter((el) => el.is_correct).length > 1
    },
    refreshTest() {
      this.$emit("refreshTest");
    },
    showResults() {
      this.showTestResults = !this.showTestResults;
      this.$refs.educationDetailTest.scrollIntoView({
        behavior: "instant",
      });
    },
    getTestPercentage() {
      let passedQuestionsCount = this.test.questions.filter(
        (question, ind) =>
          question.choices.filter(
            (answer) =>
              answer.is_correct &&
              (Array.isArray(this.questionList[ind])
                ? this.questionList[ind].includes(answer.title)
                : this.questionList[ind] === answer.title)
          ).length ===
          question.choices.filter((answer) => answer.is_correct).length
      ).length;
      this.$set(
        this,
        "testPercentage",
        (passedQuestionsCount / this.test.questions.length) * 100
      );
    },
    downloadFile(url) {
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = '';
      a.target = "_blank";
      document.body.appendChild(a);

      a.click();
      setTimeout(async () => {
        window.URL.revokeObjectURL(url);
      }, 1000);
      // a.remove();
    },
  },
  mounted() {
    this.fillQuestionsList();
  },
};
</script>

<style lang="scss" scoped>
.education-detail {
  &__content {
    &-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 24px;
      background-color: rgba(0, 37, 28, 0.502);
      border-radius: 20px 20px 0 0;
      font-family: "Roboto", sans-serif;
      font-size: 23px;
      font-weight: 500;
      line-height: 29px;
      color: var(--contentText);

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-questions-count {
      margin-left: auto;
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: var(--testCounter);

      @media screen and (max-width: 767px) {
        margin: 8px auto 0;
      }
    }

    &-body {
      border-radius: 0 0 20px 20px;
      padding: 24px;
      background: rgba(17, 17, 17, 0.2);
      display: flex;
      flex-direction: column;
    }
  }

  &__question-title {
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 29px;
    color: var(--contentText);
    margin-bottom: 32px;
  }

  &__question-checkbox {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      .education-detail {
        &__question-checkbox {
          &-correct {
            background-color: var(--testRadioBack);
          }
        }
      }
    }

    & input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      border: 0;
      padding: 0;
      margin: 0;
      z-index: 2;
      cursor: pointer;

      &:checked {
        & ~ .education-detail {
          &__question-checkbox-container {
            & > .education-detail {
              &__question-checkbox {
                &-correct {
                  background-color: var(--testRadio);
                  & > * {
                    opacity: 1;
                  }
                }
              }
            }
          }
          &__question-label {
            color: var(--testRadio);
          }
        }
      }
    }

    &-container {
      margin-top: 3px;
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      margin-right: 8px;
    }

    &-correct {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #6bc399;
      border-radius: 4px;
      transition: 0.3s;
      background-color: transparent;
      width: 100%;
      height: 100%;

      & > * {
        opacity: 0;
        flex-shrink: 0;
      }
    }
  }

  &__question-radio {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      .education-detail {
        &__question-radio {
          &-correct {
            background-color: var(--testRadioBack);
          }
        }
      }
    }

    & input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      border: 0;
      padding: 0;
      margin: 0;
      z-index: 2;
      cursor: pointer;

      &:checked {
        & ~ .education-detail {
          &__question-radio-container {
            & > .education-detail {
              &__question-radio {
                &-correct {
                  background-color: var(--testRadioBack);
                  & > * {
                    opacity: 1;
                  }
                }
              }
            }
          }
          &__question-label {
            color: #6bc399;
          }
        }
      }
    }

    &-container {
      margin-top: 3px;
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      margin-right: 8px;
    }

    &-correct {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #6bc399;
      border-radius: 50%;
      transition: 0.3s;
      background-color: transparent;
      width: 100%;
      height: 100%;

      & > * {
        opacity: 0;
        flex-shrink: 0;
      }
    }
  }

  &__question-label {
    margin: auto 0;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: var(--contentText);
    cursor: pointer;
  }

  &__test-result {
    display: flex;
    flex-direction: column;
    height: 100%;

    .education-detail {
      &__question-checkbox {
        pointer-events: none;

        &_correct {
          & > .education-detail {
            &__question-checkbox-container {
              & > .education-detail {
                &__question-checkbox {
                  &-correct {
                    background-color: #6bc399;
                    & > * {
                      opacity: 1;
                    }
                  }
                }
              }
            }

            &__question-label {
              color: #6bc399;
            }
          }
        }

        &_incorrect {
          & > .education-detail {
            &__question-checkbox-container {
              & > .education-detail {
                &__question-checkbox {
                  &-correct {
                    background-color: #da338c;
                    border-color: #da338c;
                    & > * {
                      opacity: 1;
                    }
                  }
                }
              }
            }

            &__question-label {
              color: #ec99c5;
            }
          }
        }
      }

      &__question-radio {
        pointer-events: none;

        &_correct {
          & > .education-detail {
            &__question-radio-container {
              & > .education-detail {
                &__question-radio {
                  &-correct {
                    background-color: var(--testRadioBack);
                    & > * {
                      opacity: 1;
                    }
                  }
                }
              }
            }
            &__question-label {
              color: #6bc399;
            }
          }
        }

        &_incorrect {
          & > .education-detail {
            &__question-radio-container {
              & > .education-detail {
                &__question-radio {
                  &-correct {
                    background-color: var(--testRadioBack);
                    border-color: #f6cce2;
                    & > * {
                      opacity: 1;
                    }
                  }
                }
              }
            }
            &__question-label {
              color: #ec99c5;
            }
          }
        }
      }

      &__test-question {
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-title {
      margin-bottom: 32px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 23px;
      font-weight: 400;
      line-height: 29px;
      text-align: center;
      color: var(--contentText);

      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &-button {
      margin: 0 auto;
      width: fit-content;
      padding-left: 16px;
      padding-right: 16px;
      color: #fff;
      background-color: #6bc399;

      @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 280px;
        margin: 0 auto;
        display: flex;
      }
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;

      & > .button {
        margin: 16px auto 0 !important;
        width: 100%;
        max-width: 280px;
        margin: 0 auto;
        display: flex;
      }
    }
  }
}
.button_white {
  background-color: var(--button);
  color: var(--buttonText);

  &:hover {
    background-color: var(--buttonHover);
    color: var(--buttonHoverText);
  }
}

.button_empty-white {
  color: var(--buttonEmptyText);
  border-color: var(--buttonEmpty);
  &:hover {
    background-color: var(--buttonEmptyHover);
    color: var(--buttonEmptyHoverText);
    border-color: var(--buttonEmptyHover);
  }
}
</style>